import { JL } from "jsnlog";

let initialized = false;

/** jsnlog used as log tool, because jsnlog can store log into server and automatically retry
 * if no connection at the moment. http://js.jsnlog.com/
 */
function init(): void {
	if (initialized) {
		return;
	}

	const logUrl = config.apiUrl + "/log";
	JL.setOptions({ defaultAjaxUrl: logUrl });

	const ajaxAppender = JL.createAjaxAppender("ajaxAppender");
	ajaxAppender.setOptions!({
		url: logUrl,
		level: JL.getInfoLevel()
	});

	const consoleAppender = JL.createConsoleAppender("consoleAppender");
	consoleAppender.setOptions!({
		level: JL.getTraceLevel()
	});

	JL().setOptions({
		appenders: [ajaxAppender, consoleAppender]
	});

	initialized = true;
}

function getLogger(loggerName: string): JL.JSNLogLogger {
	init();
	return JL(`Web.${loggerName}`);
}

export enum LogLevel {
	Trace = 1000,
	Debug = 2000,
	Info = 3000,
	Warn = 4000,
	Error = 5000,
	Fatal = 6000
}

/** Log into console and into server log. */
// tslint:disable-next-line: no-any
export const log = (loggerName: string, level: LogLevel, logObject: any, e?: any) => {
	getLogger(loggerName).log(level, logObject, e);
};