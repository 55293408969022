import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
	Alert,
	Button,
	Form} from "reactstrap";
import {
	Container,
	Heading,
	LinkButtonWithReturnUrl,
	PasswordInputGroup,
	PullRightContainer} from "../../components";
import { goTo } from "../../helpers/goTo";
import service from "./ResetPasswordService";

interface IRouteParams {
	userId: string;
	token: string;
}

@observer
class PerformPasswordResetPageComponent extends React.Component<RouteComponentProps<IRouteParams>> {
	@observable private isPasswordValid: boolean = false;
	@observable private isSending: boolean = false;
	@observable private submitted: boolean = false;
	@observable private error: string | null = null;

	private password: string = "";

	@action private readonly onPasswordChange = (password: string, isPasswordValid: boolean) => {
		this.password = password;
		this.isPasswordValid = isPasswordValid;
	}

	private readonly resetPassword = async (e: React.MouseEvent<Button, MouseEvent>) => {
		e.preventDefault();
		runInAction(() => this.submitted = true);
		if (!this.isPasswordValid) {
			return;
		}

		runInAction(() => {
			this.isSending = true;
			this.error = null;
		});

		const { userId, token } = this.props.match.params;
		const { success, error } = await service.performPasswordReset(userId, this.password, token);
		if (!success) {
			runInAction(() => {
				this.isSending = false;
				this.error = error;
			});
		} else {
			goTo("/login", this.props, { hasJustResetPassword: "true" });
		}
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Heading smallVerticalMargin>
					<h2>Password reset</h2>
					<p>Please enter the new password for your account.</p>
				</Heading>

				<Form name="form">
					{this.error && <Alert color="danger">{this.error}</Alert>}
					<PasswordInputGroup
						submitted={this.submitted}
						disabled={this.isSending}
						onPasswordChange={this.onPasswordChange} />
					<PullRightContainer>
						<Button
							type="button"
							id="perform-password-reset"
							color="secondary"
							disabled={!this.isPasswordValid || this.isSending}
							onClick={this.resetPassword}>
							{this.isSending ? "Resetting..." : "Reset"}
						</Button>
						<LinkButtonWithReturnUrl to="/login">Cancel</LinkButtonWithReturnUrl>
					</PullRightContainer>
				</Form>
			</Container>);
	}
}

export const PerformPasswordResetPage = withRouter(PerformPasswordResetPageComponent);