import React from "react";
import { Input, InputProps } from "reactstrap";
import styled from "styled-components";

const InputWithPaddingRight = styled(Input)`
	padding-right: 34px;
`;

export const InputWithIcon = (p: InputProps) => {
	const { children, ...inputProps } = p;
	return (
		<React.Fragment>
			<InputWithPaddingRight {...inputProps} />
			{children}
		</React.Fragment>);
};