import React from "react";
import styled from "styled-components";

const Container = styled.div`
	position: absolute;
	bottom: 1px;
	right: 10px;
`;

const Link = styled.a`
	color: #999;
	text-decoration: none;
	font-size: 13px;

	&:hover, :active {
		color: #999;
		text-decoration: underline;
	}
`;

const SeparatorContainer = styled.span`
	margin: 0 6px;
	color: #999;
`;

const Separator = (p: React.PropsWithChildren<{}>) =>
	<SeparatorContainer>|</SeparatorContainer>;

export const FooterLinks = (p: React.PropsWithChildren<{}>) =>
	<Container>
		<Link href="https://www.across.net/en/legal-notice">
			Legal notice
		</Link>
		<Separator />
		<Link href="https://www.across.net/en/legal-notice/gtc">
			GTC
		</Link>
		<Separator />
		<Link href="https://www.across.net/en/legal-notice/privacy-statement">
			Privacy Statement
		</Link>
	</Container>;