import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { DelayedContainer } from "../../components";
import { getQueryStringParameter } from "../../helpers/queryString";
import { makeUrl } from "../../infrastructure/httpClient";

const Info = styled.div`
	text-align: center;
`;

class RedirectPageComponent extends React.Component<RouteComponentProps> {
	private readonly toCrossMarket: boolean = getQueryStringParameter("toCrossMarket") === "1";

	public componentDidMount(): void {
		document.title = "Redirect - Across";

		const returnUrl = getQueryStringParameter("ReturnUrl");
		if (returnUrl === null) {
			if (this.toCrossMarket) {
				window.location.href = makeUrl("redirect", {
					url: null,
					clientId: "cm-web",
					doNotShowRedirectPage: "true"
				});
			}

			// Redirect to default route to let application decide which page, login or account,
			// should be displayed based on user logged in state.
			this.props.history.replace("/");
			return;
		}

		const clientId = getQueryStringParameter("clientId");
		// Remove redirect page from history, to avoid user return to this page.
		window.history.replaceState({}, window.document.title, window.location.origin);
		window.location.href = makeUrl("redirect", {
			url: returnUrl,
			clientId,
			doNotShowRedirectPage: "true"
		});
		return;
	}

	public render(): React.ReactNode {
		return (
			<DelayedContainer>
				<Info>
					<h2>You are now being returned to the application</h2>
					<p>Once complete, you may close this tab</p>
				</Info>
			</DelayedContainer>);
	}
}

export const RedirectPage = withRouter(RedirectPageComponent);