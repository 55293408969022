import React from "react";
import { Button, Col } from "reactstrap";
import styled from "styled-components";

export const LogoutButton = styled(Button)`
	&& {
		position: absolute;
		top: 7px;
		right: 11px;
		height: auto;
		padding: 0;
		margin: 0;
		color: #7c8082;
		border: none;
		background: none;
		box-shadow: none;
		font-size: 11px;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

		&:hover, &:focus {
			color: #7c8082 !important;
			background: none;
			box-shadow: none;
		}

		&:hover {
			text-decoration: underline;
		}

		&:active {
			color: #7c8082;
			background: none !important;
			background-color: none !important;
			box-shadow: none !important;
		}
	}
`;

export const Heading = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
	text-align: center;
`;

export const HeadlineBlock = styled.div`
	display: block;
	position: relative;
	margin: 25px 0;
	border-bottom: 1px dotted #e4e9f0;
`;

export const HeadlineText = styled.h4`
	display: inline-block;
	margin: 0 0 -2px;
	padding-bottom: 5px;
	border-bottom: 2px solid ${p => p.theme.colors.primary};
	color: #585f69;
	text-shadow: none;
	font-size: 18px;
	font-weight: 400;
	line-height: 25px;
`;

export const Headline = (p: { text: string }) =>
	<React.Fragment>
		<HeadlineBlock>
			<HeadlineText>{p.text}</HeadlineText>
		</HeadlineBlock>
	</React.Fragment>;

const LeftBlockCol = styled(Col)`
	height: 28px;
	text-align: right;
	padding: 0;
	font-size: 13px;
	font-weight: 700;
	line-height: 28px;
	color: #585f69;
`;

export const LeftBlock = (p: { children: React.ReactNode }) =>
	<LeftBlockCol xs="3">{p.children}</LeftBlockCol>;

export const RightBlockCol = styled(Col)`
	text-align: left;
	padding: 0 0 0 12px;
	font-size: 13px;
	font-weight: 500;
	line-height: 28px;
	color: #585f69;
`;

export const RightBlock = (p: { children: React.ReactNode }) =>
	<RightBlockCol xs="9">{p.children}</RightBlockCol>;

export const EditButton = styled(Button)`
	&& {
		display: inline-block;
		height: 28px;
		position: absolute;
		right: 0;
		z-index: 2;
		text-align: left;
		padding: 3px 12px;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.428571;
	}
`;

const EditButtonBarOuter = styled.div`
	height: 30px;
`;

const EditButtonBarInner = styled.div`
	padding: 10px 0 15px;
	float: right;

	.btn:last-child {
		margin-left: 5px;
	}
`;

export const EditButtonBar = (p: { children: React.ReactNode }) =>
	<EditButtonBarOuter>
		<EditButtonBarInner>{p.children}</EditButtonBarInner>
	</EditButtonBarOuter>;

export const ReturnButtonBlock = styled(Col)`
	margin-top: 25px;
	text-align: center;
`;