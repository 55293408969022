import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { configure } from "mobx";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app/App";
import { ErrorHandler } from "./app/ErrorHandler";

configure({ enforceActions: "always" });

// Add all brand and solid Font Awesome SVG icon resources to application.
library.add(fas, fab);

const baseTag = document.getElementsByTagName("base")[0];
const baseUrl = baseTag.getAttribute("href")!;

ReactDOM.render(
	<BrowserRouter basename={baseUrl}>
		<ErrorHandler>
			<App />
		</ErrorHandler>
	</BrowserRouter>,
	document.getElementById("app"));