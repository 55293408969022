import http from "../../infrastructure/httpClient";
import { SignupDto } from "./dto/SignupDto";
import { SignupResultDto } from "./dto/SignupResultDto";

const SignupService = {
	signup: (
		username: string,
		password: string,
		returnUrl: string): Promise<SignupResultDto> =>
		http.post("signup", new SignupDto(
			username,
			password,
			returnUrl
		)),

	isEmailAvailable: (email: string): Promise<boolean> =>
		http.post("signup/is-email-available", email)
};

export default SignupService;