import { action, computed, observable, runInAction } from "mobx";
import http from "../../infrastructure/httpClient";

export interface IExternalProviderDto {
	displayName: string;
	name: string;
	linked: boolean;
}

interface IAccountDataDto {
	email: string;
	hasPassword: boolean;
	externalLogins: IExternalProviderDto[];
}

// NB: Keep in sync with server side.
export enum ChangeEmailResult {
	Success = 1,
	InvalidEmail,
	EmailHasNotChanged,
	UnableToUpdateUser,
	UnableToSendConfirmation
}

class AccountService {
	private isDataLoaded: boolean = false;

	@observable public email: string | null = null;
	@observable public hasPassword: boolean | null = null;
	@observable public externalLogins: IExternalProviderDto[] | null = null;

	@computed public get loggedIn(): boolean | null {
		if (!this.isDataLoaded) {
			return null;
		}

		return this.email !== null;
	}

	/**
	 * Loads account data of the logged in user. Doesn't reload if data already loaded.
	 */
	public async loadAccountData(): Promise<void> {
		if (this.isDataLoaded) {
			return;
		}

		const data = await http.get<IAccountDataDto>("account");
		runInAction(() => {
			if (data !== null) {
				this.email = data.email;
				this.hasPassword = data.hasPassword;
				this.externalLogins = data.externalLogins;
			}

			this.isDataLoaded = true;
		});
	}

	/**
	 * Invalidates the account data, so at the next load request it will be reloaded.
	 */
	@action public invalidateAccountData(): void {
		this.email = null;
		this.hasPassword = null;
		this.externalLogins = null;
		this.isDataLoaded = false;
	}

	/**
	 * Sends e-mail confirmation to change e-mail address.
	 */
	public changeEmail(newEmail: string): Promise<ChangeEmailResult> {
		return http.post<ChangeEmailResult>("account/change-email", newEmail);
	}

	/**
	 * Removes external login from current user.
	 */
	public async unlinkExternalLogin(provider: string): Promise<void> {
		const error = await http.post<void>(
			"account/external-login/unlink",
			provider);

		if (error === null) {
			this.isDataLoaded = false;
			await this.loadAccountData();
		}

		return error;
	}

	/**
	 * Deletes logged in user.
	 */
	public delete(): Promise<string> {
		return http.delete("account");
	}
}

const instance = new AccountService();
export default instance;