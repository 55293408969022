import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
	@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);

	html, body, #app {
		height: 100%;
		margin: 0;
		line-height: 1.6;
	}

	h1, h2, h3, h4, h5, h6, span, div, label, p {
		color: ${p => p.theme.colors.textPrimary};
		font-family: "Open Sans", sans-serif;
	}

	h2 {
		font-size: 28px;
		margin-top: 5px;
		margin-bottom: 15px;
	}

	a {
		color: ${p => p.theme.colors.textPrimary};
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		text-decoration: underline;

		&:hover {
			color: ${p => p.theme.colors.textPrimary};
		}
	}

	.btn {
		height: 34px;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 14px;
		border-radius: 0;
		text-decoration: none;

		&.btn-primary {
			background-color: ${p => p.theme.colors.primary};
			border-color: ${p => p.theme.colors.primary};

			&:disabled {
				background-color: ${p => p.theme.colors.primaryDisabled};
				border-color: ${p => p.theme.colors.primaryDisabled};
			}

			&:hover, &:focus {
				background-color: ${p => p.theme.colors.primaryHighlight};
				box-shadow: none;
			}

			&:active {
				background-color: ${p => p.theme.colors.primaryHighlight} !important;
				box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
			}
		}

		&.btn-secondary {
			background-color: ${p => p.theme.colors.secondary};
			border-color: ${p => p.theme.colors.secondary};

			&:disabled {
				background-color: ${p => p.theme.colors.secondaryDisabled};
				border-color: ${p => p.theme.colors.secondaryDisabled};
			}

			&:hover, &:focus {
				background-color: ${p => p.theme.colors.secondaryHighlight};
				box-shadow: none;
			}

			&:active {
				background-color: ${p => p.theme.colors.secondaryHighlight} !important;
				box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
			}
		}

		&.btn-default {
			background-color: ${p => p.theme.colors.default};
			border-color: ${p => p.theme.colors.defaultBorder};

			&:hover, &:focus {
				background-color: ${p => p.theme.colors.defaultHighlight};
				box-shadow: none;
			}

			&:active {
				background-color: ${p => p.theme.colors.defaultHighlight} !important;
				box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
			}

			&:disabled, &:hover:disabled, &:focus:disabled {
				background-color: ${p => p.theme.colors.defaultDisabled};
				border-color: ${p => p.theme.colors.defaultDisabled};
			}
		}
	}

	p {
		color: ${p => p.theme.colors.textPrimary};
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 13px;
		margin: 0 0 10px;
	}

	span {
		color: ${p => p.theme.colors.textPrimary};
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 13px;
	}

	.alert {
		padding: 15px;
		border: 1px solid transparent;
		border-radius: 0;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 13px;
		text-align: left;
		margin-bottom: 20px;

		&.alert-success {
			color: #3c763d;
			background-color: #dff0d8;
			border-color: #d6e9c6;
		}

		&.alert-danger {
			color: #a94442;
			background-color: #f2dede;
			border-color: #ebccd1;
		}
	}

	.input-group {
		margin-top: ${p => p.theme.margins.top};
		button {
			margin-top: 0;
		}

		input, span {
			height: 34px;
			border: 1px solid #ccc;
			border-radius: 0;
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-size: 14px;
			line-height: 20px;
		}

		input::-webkit-input-placeholder { /* Edge */
			color: ${p => p.theme.colors.textSecondary};
		}

		input:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: ${p => p.theme.colors.textSecondary};
		}

		input::placeholder {
			color: ${p => p.theme.colors.textSecondary};
		}

		input:active, input:focus {
			border-color: #bbb;
			box-shadow: 0 0 2px #c9c9c9;
		}

		.input-group-text {
			color: ${p => p.theme.colors.textSecondary};
			background: ${p => p.theme.colors.background};
			display: inline-block;
			text-align: center;
			font-size: 14px;
			padding: 6px 12px;
			vertical-align: middle;
			min-width: 41px;
		}

		&.is-invalid {
			.form-control {
				border-color: #a94442;

				&:active, &:focus {
					border-color: #a94442;
					box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
				}
			}

			.input-group-text {
				color: #a94442;
				background-color: #f2dede;
				border-color: #a94442;
			}

			.invalid-feedback {
				display: block;
				margin-top: 0;
				color: #a94442;
				font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
				font-size: 13px;
			}

			svg {
				color: #a94442 !important;
			}
		}
	}

	.form-control.is-invalid {
		border: 1px solid #dc3545;
		border-radius: 0;

		&:active, &:focus {
			box-shadow: 0 0 2px #dc3545;
		}
	}

	.form-check {
		.form-check-label {
			color: ${p => p.theme.colors.textPrimary};
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-size: 13px;
			cursor: pointer;
		}

		input[type=checkbox] {
			margin-top: 0.2rem;
		}
	}

	@media (min-width: 992px) {
		.modal-lg {
			max-width: 600px;
		}
	}

	.modal-content {
		box-shadow: 0 5px 15px rgba(0,0,0,.5);
		border-radius: 0px;

		.modal-header, .modal-body {
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		}
	}
`;