import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ThemeProvider } from "styled-components";
import CrossMarketTheme from "../styles/CrossMarketTheme";
import { GlobalStyles } from "../styles/GlobalStyles";
import { AccountPage } from "./account/AccountPage";
import { DeletePage } from "./account/DeletePage";
import {
	EmailConfirmationFailedPage } from "./email-confirmation-failed/EmailConfirmationFailedPage";
import { EmailNotConfirmedPage } from "./email-not-confirmed/EmailNotConfirmedPage";
import { ServerErrorPage } from "./serverError/ServerErrorPage";
import { Layout } from "./Layout";
import { LoadingPage } from "./loading/LoadingPage";
import { LoginPage } from "./login/LoginPage";
import { EndSessionPage } from "./logout/EndSessionPage";
import { LogoutPage } from "./logout/LogoutPage";
import { RedirectPage } from "./redirect/RedirectPage";
import {
	ResendConfirmationEmailPage } from "./resend-confirmation-email/ResendConfirmationEmailPage";
import { PerformPasswordResetPage } from "./resetPassword/PerformPasswordResetPage";
import { SendPasswordResetEmailPage } from "./resetPassword/SendPasswordResetEmailPage";
import { SignupSuccessPage } from "./signup-success/SignupSuccessPage";
import { SignupPage } from "./signup/SignupPage";

export const App = () =>
	<ThemeProvider theme={CrossMarketTheme}>
		<Layout>
			<GlobalStyles />
			<Switch>
				<Route path="/account/delete" component={DeletePage} />
				<Route path="/account" component={AccountPage} />
				<Route path="/login" component={LoginPage} />
				<Route path="/logout/end-session" component={EndSessionPage} />
				<Route path="/logout" component={LogoutPage} />
				<Route path="/redirect" component={RedirectPage} />
				<Route path="/signup" component={SignupPage} />
				<Route path="/signup-success" component={SignupSuccessPage} />
				<Route path="/email-not-confirmed" component={EmailNotConfirmedPage} />
				<Route path="/email-confirmation-failed" component={EmailConfirmationFailedPage} />
				<Route path="/resend-confirmation-email" component={ResendConfirmationEmailPage} />
				<Route path="/send-password-reset-email" component={SendPasswordResetEmailPage} />
				<Route path="/perform-password-reset/:userId/:token" component={PerformPasswordResetPage} />
				<Route path="/server-error" component={ServerErrorPage} />
				<Route exact path="" component={LoadingPage} />
				<Redirect from="*" to="" />
			</Switch>
		</Layout>
	</ThemeProvider>;