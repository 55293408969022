import { LocationDescriptor } from "history";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { withReturnUrl } from "../helpers/goTo";
import { getQueryStringParameter } from "../helpers/queryString";

export class LinkWithReturnUrl extends React.Component<LinkProps> {
	private makeTo(): LocationDescriptor | string {
		const returnUrl = getQueryStringParameter("ReturnUrl");
		if (!returnUrl) {
			return this.props.to;
		}

		if (typeof this.props.to !== "string") {
			throw new Error("Only string 'to' property is supported.");
		}

		return withReturnUrl(this.props.to);
	}

	public render(): React.ReactNode {
		const propsWithReturnUrl = { ...this.props };
		propsWithReturnUrl.to = this.makeTo();

		return <Link {...propsWithReturnUrl} />;
	}
}

export const LinkButtonWithReturnUrl = (p: LinkProps) =>
	<LinkWithReturnUrl {...p} className="btn btn-default" role="button" />;