import { FontAwesomeIcon, Props as FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React, { SyntheticEvent } from "react";
import { FormFeedback, InputGroupAddon, InputGroupText } from "reactstrap";
import styled from "styled-components";
import { InputGroupWithValidation } from "./InputGroupWithValidation";
import { InputWithIcon } from "./InputWithIcon";
import { PasswordStrength } from "./PasswordStrength";

interface IProps {
	submitted: boolean;
	disabled?: boolean;

	onPasswordChange: (password: string, isPasswordValid: boolean) => void;
}

const FontAwesomeIconWithDomAttributes = (
	p: FontAwesomeIconProps & React.DOMAttributes<HTMLOrSVGElement>) =>
	<FontAwesomeIcon {...p} />;

const PasswordHideToggle = styled(FontAwesomeIconWithDomAttributes)`
	&& {
		color: ${p => p.theme.colors.textSecondary};
		position: absolute;
		right: 0;
		width: 34px;
		height: 34px;
		padding: 9px 7px;
		z-index: 100;
		cursor: pointer;
	}
`;

const PasswordHint = styled.p`
	margin: 5px 0;
`;

@observer
export class PasswordInputGroup extends React.Component<IProps> {

	private static readonly PasswordMinLength = 8;
	private static readonly PasswordStrongLength = 30;

	@observable private hidePassword: boolean = true;
	@observable private password: string = "";
	@observable private isPasswordValid: boolean = false;

	@action private readonly onPasswordChange = (event: SyntheticEvent<HTMLInputElement>) => {
		this.password = event.currentTarget.value;
		this.isPasswordValid = this.password.length >= PasswordInputGroup.PasswordMinLength;

		this.props.onPasswordChange(this.password, this.isPasswordValid);
	}

	@action private readonly toggleHidePassword = () => {
		this.hidePassword = !this.hidePassword;
	}

	public render(): React.ReactNode {
		return (
			<InputGroupWithValidation
				id="password-field"
				isInvalid={this.props.submitted && !this.isPasswordValid}>
				<InputGroupAddon addonType="prepend">
					<InputGroupText>
						<FontAwesomeIcon icon="lock" />
					</InputGroupText>
				</InputGroupAddon>
				<InputWithIcon
					type={this.hidePassword ? "password" : "text"}
					name="password"
					placeholder="Password"
					required
					autoComplete="new-password"
					onChange={this.onPasswordChange}
					disabled={this.props.disabled}>
					<PasswordHideToggle
						className="password-hide-toggle"
						icon={this.hidePassword ? "eye" : "eye-slash"}
						onClick={this.toggleHidePassword} />
				</InputWithIcon>
				<PasswordStrength
					password={this.password}
					minLength={PasswordInputGroup.PasswordMinLength}
					strongLength={PasswordInputGroup.PasswordStrongLength} />
				<FormFeedback valid={this.isPasswordValid}>
					The password must be at least 8 characters long.
				</FormFeedback>
				<PasswordHint>
					Stronger passwords must include lower and uppercase characters
					along with numbers and symbols.
				</PasswordHint>
			</InputGroupWithValidation>
		);
	}
}