import { Button } from "reactstrap";
import styled from "styled-components";

export const RegistrationHint = styled.p`
	margin: 20px 0 30px;
	text-align: center;
`;

export const SignupButton = styled(Button)`
	&& {
		margin-top: 10px;
	}
`;