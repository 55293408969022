import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Form } from "reactstrap";
import styled from "styled-components";
import { Container } from "../../components";
import { getQueryStringParameter } from "../../helpers/queryString";
import { makeUrl } from "../../infrastructure/httpClient";

const Header = styled.h2`
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
`;

class LogoutPageComponent extends React.Component<RouteComponentProps> {
	private readonly logoutId: string | null = getQueryStringParameter("logoutId");

	public componentDidMount(): void {
		document.title = "Log out - Across";
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Header>
					Would you like to logout of Across?
				</Header>
				<Form action={makeUrl("account/logout")} method="POST">
					{this.logoutId !== null &&
						<input type="hidden" name="logoutId" value={this.logoutId} />}
					<Button type="submit">Log Out</Button>
				</Form>
			</Container>
		);
	}
}

export const LogoutPage = withRouter(LogoutPageComponent);