import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { SyntheticEvent } from "react";
import { Button, FormFeedback, Input, InputGroup } from "reactstrap";
import styled from "styled-components";
import { InputGroupWithValidation } from "../../../components";
import { EmailRegex } from "../../../helpers/regex";
import service, { ChangeEmailResult } from "../../../services/account/AccountService";
import ResetPasswordService from "../../resetPassword/ResetPasswordService";
import * as C from "./StyledComponents";

export interface IChangeEmailProps {
	isSubmitting: boolean;
	beginSubmit: () => void;
	endSubmit: () => void;
	setSuccess: (message: string) => void;
	setError: (message: string) => void;
}

const EmailBlockCol = styled(C.RightBlockCol)`
	word-break: break-all;
`;

export const EmailBlock = (p: { children: React.ReactNode }) =>
	<EmailBlockCol xs="7">{p.children}</EmailBlockCol>;

@observer
export class LoginData extends React.Component<IChangeEmailProps> {
	@observable private edit: null | "email" | "password" = null;
	@observable private isEmailInvalid: boolean = true;
	@observable private submitted: boolean = false;
	private newEmail: string = "";

	@action private readonly onNewEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
		this.newEmail = event.currentTarget.value;
		this.isEmailInvalid = !this.newEmail || !EmailRegex.test(this.newEmail);
	}

	@action private readonly editEmail = () => {
		this.edit = "email";
	}

	@action private readonly editPassword = () => {
		this.edit = "password";
	}

	@action private readonly endEdit = () => {
		this.edit = null;
	}

	private readonly changeEmail = async () => {
		runInAction(() => this.submitted = true);
		if (this.isEmailInvalid) {
			return;
		}

		this.props.beginSubmit();
		try {
			const result = await service.changeEmail(this.newEmail);
			this.processChangeEmailResult(result);

			runInAction(() => {
				this.newEmail = "";
				this.isEmailInvalid = true;
				this.submitted = false;
				this.edit = null;
			});
		} finally {
			this.props.endSubmit();
		}
	}

	@action private processChangeEmailResult(result: ChangeEmailResult): void {
		switch (result) {
			case ChangeEmailResult.Success:
				this.props.setSuccess(
					"An e-mail with the confirmation link has been sent to the new address. "
					+ "Please check your inbox.");
				break;
			case ChangeEmailResult.InvalidEmail:
				this.props.setError("You have entered invalid e-mail address.");
				break;
			case ChangeEmailResult.EmailHasNotChanged:
				this.props.setError("You have entered same e-mail as already used.");
				break;
			case ChangeEmailResult.UnableToUpdateUser:
				this.props.setError(
					"Failed to change e-mail address. If problem persists, please contact "
					+ "an administrator.");
				break;
			case ChangeEmailResult.UnableToSendConfirmation:
				this.props.setError("Failed to send confirmation e-mail. Please try again later.");
				break;
		}
	}

	private readonly changePassword = async () => {
		this.props.beginSubmit();
		try {
			await ResetPasswordService.sendPasswordResetEmail(service.email!);
			runInAction(() => this.props.setSuccess(
				"An e-mail with instructions has been sent. Please check your inbox."));
		} catch {
			runInAction(() => this.props.setError(
				"Failed to send a reset password e-mail. Please try again later."));
		} finally {
			this.props.endSubmit();
		}
	}

	public render(): React.ReactNode {
		return (
			<React.Fragment>
				<C.Headline text="Login data" />
				{this.edit !== "password" &&
					<InputGroup>
						<C.LeftBlock>
							E-mail
						</C.LeftBlock>
						<EmailBlock>
							{service.email}
						</EmailBlock>
						{this.edit === null &&
							<C.EditButton
								id="account_edit-email"
								color="default"
								disabled={this.props.isSubmitting}
								onClick={this.editEmail}>
								Edit
							</C.EditButton>}
					</InputGroup>}
				{this.edit === null &&
					<InputGroup>
						<C.LeftBlock>
							Password
						</C.LeftBlock>
						<C.RightBlock>
							{service.hasPassword &&
								<React.Fragment>********</React.Fragment>}
							{!service.hasPassword &&
								<i>[not set]</i>}
						</C.RightBlock>
						<C.EditButton
							id="account_edit-password"
							color="default"
							disabled={this.props.isSubmitting}
							onClick={this.editPassword}>
							Edit
						</C.EditButton>
					</InputGroup>}
				{this.edit === "email" &&
					<React.Fragment>
						<InputGroupWithValidation
							isInvalid={this.submitted && this.isEmailInvalid}>
							<C.LeftBlock>
								New e-mail
							</C.LeftBlock>
							<C.RightBlock>
								<Input
									type="email"
									disabled={this.props.isSubmitting}
									onChange={this.onNewEmailChange} />
								<FormFeedback valid={!this.isEmailInvalid}>
									E-mail is invalid.
								</FormFeedback>
							</C.RightBlock>
						</InputGroupWithValidation>
						<C.EditButtonBar>
							<Button
								id="account_save-email"
								color="secondary"
								disabled={this.props.isSubmitting}
								onClick={this.changeEmail}>
								Save
							</Button>
							<Button
								id="account_cancel-edit-email"
								color="default"
								disabled={this.props.isSubmitting}
								onClick={this.endEdit}>
								Cancel
							</Button>
						</C.EditButtonBar>
					</React.Fragment>}
				{this.edit === "password" &&
					<React.Fragment>
						<p>
							Click "Send" to receive an e-mail with instructions on how to change or
							set the password.
						</p>
						<C.EditButtonBar>
							<Button
								id="account_send-password"
								color="secondary"
								disabled={this.props.isSubmitting}
								onClick={this.changePassword}>
								Send
							</Button>
							<Button
								id="account_cancel-edit-password"
								color="default"
								disabled={this.props.isSubmitting}
								onClick={this.endEdit}>
								Cancel
							</Button>
						</C.EditButtonBar>
					</React.Fragment>}
			</React.Fragment>);
	}
}