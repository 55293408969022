import { DefaultTheme } from "styled-components";

const CrossMarketTheme: DefaultTheme = {
	colors: {
		primary: "#72c02c",
		primaryHighlight: "#5fb611",
		primaryDisabled: "#72c02c",
		secondary: "#002d5c",
		secondaryHighlight: "#00264e",
		secondaryDisabled: "#002d5c",
		default: "#fff",
		defaultHighlight: "#e6e6e6",
		defaultDisabled: "#adadad",
		defaultBorder: "#ccc",
		background: "#fff",
		textPrimary: "#777",
		textSecondary: "#b3b3b3",
		error: "#d9534f"
	},
	margins: {
		top: "20px",
		bottom: "20px"
	}
};

export default CrossMarketTheme;