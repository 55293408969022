import { observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import styled from "styled-components";
import { Container, LoadingIndicator } from "../../components";
import { getQueryStringParameter } from "../../helpers/queryString";
import service from "./ServerErrorService";

const Heading = styled.div`
	text-align: center;
	margin-bottom: ${p => p.theme.margins.bottom};
`;

const LinkGreen = styled(Link)`
	color: ${p => p.theme.colors.primary};
`;

@observer
class ServerErrorPageComponent extends React.Component<RouteComponentProps> {
	@observable private description: string | null = null;

	public async componentDidMount(): Promise<void> {
		const errorId = getQueryStringParameter("errorId", this.props.location.search);

		if (errorId) {
			const { error, description } = await service.getError(errorId);
			runInAction(() => this.description = description || error);
		} else {
			runInAction(() =>
				this.description = "There was an error processing your request.");
		}
	}

	public render(): React.ReactNode {
		return (
			<Container isError>
				<Heading>
					<h1>Server Error</h1>
				</Heading>
				{this.description && <React.Fragment>
					<p>Unable to process your request.</p>
					<Alert color="danger">{this.description}</Alert>
					<p>You could go to the <LinkGreen to="/login">login page</LinkGreen>.</p>
				</React.Fragment>}

				{!this.description && <LoadingIndicator />}
			</Container>);
	}
}

export const ServerErrorPage = withRouter(ServerErrorPageComponent);