import React from "react";
import { Alert } from "reactstrap";

export interface IAlertBlockProps {
	message: string | null;
	error: boolean;
}

export const AlertBlock = (p: IAlertBlockProps) =>
	<React.Fragment>
		{p.message !== null &&
			<Alert color={p.error ? "danger" : "success"}>{p.message}</Alert>}
	</React.Fragment>;