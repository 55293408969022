interface IHttpError extends Error {
	data?: IHttpErrorData;
}

interface IHttpErrorData {
	url: string;
	headers: object;
	method: string;
	status?: number;
	statusText?: string;
}

export function makeUrl(url: string, queryParams?: { [key: string]: string | null }): string {
	const fullUrl = new URL(`${config.apiUrl}/${url}`);
	if (queryParams) {
		Object.keys(queryParams)
			.filter(key => queryParams[key] !== null)
			.forEach(key => fullUrl.searchParams.append(key, queryParams[key] as string));
	}

	return fullUrl.href;
}

function errorResponseToMessage(r: Response): string {
	const contactToSupport = "Please contact the Across support desk for assistance.";
	switch (r.status) {
		case 403:
			return "You do not have sufficient permissions to access this resource.";
		case 500:
			return `A server error has occurred. ${contactToSupport}`;
		default:
			return `An error has occurred. ${contactToSupport}`;
	}
}

async function parseResponse<T>(
	response: Response,
	getLogData: () => IHttpErrorData): Promise<T> {
	const result: string = await response.text();
	if (!response.ok) {
		const error = result.length ? JSON.parse(result) : errorResponseToMessage(response);
		const e: IHttpError = new Error(error);

		// Log error details.
		const logData = getLogData();
		logData.status = response.status;
		logData.statusText = response.statusText;

		e.data = logData;
		throw e;
	}

	return result.length ? JSON.parse(result) : null;
}

async function request<T>(
	method: "GET" | "POST" | "DELETE",
	url: string,
	queryParams?: { [key: string]: string },
	body?: unknown): Promise<T> {

	const requestUrl = makeUrl(url, queryParams);
	const headers: { [key: string]: string } = {
		"Accept": "application/json"
	};

	if (method !== "GET") {
		headers["Content-Type"] = "application/json";
	}

	function getLogData(): IHttpErrorData {
		return {
			url: requestUrl,
			headers: headers,
			method: method
		};
	}

	let response: Response;
	try {
		response = await fetch(requestUrl, {
			credentials: "include",
			headers,
			method,
			body: body ? JSON.stringify(body) : undefined
		});
	} catch (e) {
		if (e.message === "Failed to fetch") {
			// Overwrite message to more user friendly.
			e.message = "Could not connect to the server. "
				 + "Please check your Internet connection and try again.";
		}
		e.data = getLogData();
		throw e;
	}
	return await parseResponse(response, getLogData);
}

const HttpClient = {
	get: async <T>(url: string, queryParams?: { [key: string]: string }): Promise<T> => {
		return request<T>("GET", url, queryParams, undefined);
	},

	post: async <T>(url: string, body?: unknown): Promise<T> => {
		return request<T>("POST", url, undefined, body);
	},

	delete: async <T>(url: string, body?: unknown): Promise<T> => {
		return request<T>("DELETE", url, undefined, body);
	}
};

export default HttpClient;