import http from "../../infrastructure/httpClient";

interface IResetPasswordResultDto {
	success: boolean;
	error: string;
}

const ResetPasswordService = {
	sendPasswordResetEmail: (email: string): Promise<void> =>
		http.post("reset-password/send", email),

	performPasswordReset: (userId: string, password: string, token: string)
		: Promise<IResetPasswordResultDto> =>
		http.post("reset-password/perform", { userId, password, token })
};

export default ResetPasswordService;