import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import { FooterLinks } from "../components/FooterLinks";

const LayoutBackground = styled.div`
	min-height: 100%;
	background: url(./assets/Hintergrund_Login.jpg) center center no-repeat fixed;
	background-size: cover;
`;

const LayoutContainer = styled(Container)`
	padding-top: 60px;
	padding-bottom: 10px;
`;

export const Layout = (p: React.PropsWithChildren<{}>) =>
	<LayoutBackground>
		<LayoutContainer>
			{p.children}
		</LayoutContainer>
		<FooterLinks />
	</LayoutBackground>;