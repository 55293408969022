import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import styled from "styled-components";
import { Container } from "../../components";
import { goTo } from "../../helpers/goTo";
import { getQueryStringParameter } from "../../helpers/queryString";

const Header = styled.h2`
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
`;

const Text = styled.p`
	margin-bottom: 20px;
`;

class EmailConfirmationFailedPageComponent extends React.Component<RouteComponentProps> {
	private email: string = "";

	public componentDidMount(): void {
		document.title = "E-mail confirmation failed - Across";
		this.email = getQueryStringParameter("email") || "";
	}

	private readonly goToResendEmailConfirmation = () => {
		goTo("/resend-confirmation-email", this.props, { email: this.email });
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Header>
					Confirmation e-mail could not be sent
				</Header>
				<Text>
					Your account has been successfully created. Unfortunately, we were unable
					to send you a confirmation e-mail. Please request it anew in order to
					complete your registration.
				</Text>
				<Row>
					<Col md={{ size: 10, offset: 1 }} sm={12}>
						<Button
							type="button"
							color="primary"
							block
							onClick={this.goToResendEmailConfirmation}>
							Request confirmation e-mail anew
						</Button>
					</Col>
				</Row>
			</Container>
		);
	}
}

export const EmailConfirmationFailedPage = withRouter(EmailConfirmationFailedPageComponent);