import styled, { keyframes } from "styled-components";
import { Container } from "./Container";

const DelayedShowAnimation = keyframes`
to {
	visibility: visible;
}
`;

/**
 * Container with delayed display UI, used for pages with redirect to reduce UI flickering.
 * UI displayed after 2 seconds.
 */
export const DelayedContainer = styled(Container)`
	visibility: hidden;
	animation: 0s linear 2s forwards ${DelayedShowAnimation};
`;