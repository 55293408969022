import http from "../../infrastructure/httpClient";
import { ExternalProviderDto } from "./dto/ExternalProviderDto";
import { LoginDto } from "./dto/LoginDto";
import { LoginStatus } from "./dto/LoginStatus";

const LoginService = {
	getExternalProviders: (): Promise<ExternalProviderDto[]> =>
		http.get("login/external-providers"),

	login: (
		username: string,
		password: string,
		rememberLogin: boolean,
		returnUrl: string): Promise<LoginStatus> =>
		http.post("login", new LoginDto(
			username,
			password,
			rememberLogin,
			returnUrl
		))
};

export default LoginService;