import { action } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import styled from "styled-components";
import { Container, Separator } from "../../components";
import { goTo } from "../../helpers/goTo";

const Heading = styled.div`
	text-align: center;
	margin-bottom: 30px;

	h2 {
		margin-bottom: 20px;
	}
`;

@observer
class EmailNotConfirmedPageComponent extends React.Component<RouteComponentProps> {
	public componentDidMount(): void {
		document.title = "E-mail not confirmed - Across";
	}

	@action private readonly goToResendConfirmationEmail = () => {
		goTo("/resend-confirmation-email", this.props);
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Heading>
					<h2>E-mail confirmation is required</h2>
					<Separator />
				</Heading>
				<p>
					You have to confirm your registration before you can access
					your account.
				</p>
				<p>
					Please follow the instructions sent to the e-mail address provided
					during the sign up process.
				</p>
				<Row>
					<Col md={{ size: 10, offset: 1 }} sm={12}>
						<Button
							type="button"
							color="primary"
							block
							onClick={this.goToResendConfirmationEmail}>
							Resend Confirmation
						</Button>
					</Col>
				</Row>
			</Container>
		);
	}
}

export const EmailNotConfirmedPage = withRouter(EmailNotConfirmedPageComponent);