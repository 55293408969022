import styled from "styled-components";
import { LoadingIndicator } from "../../../components";

export const RestorePassword = styled.div`
	margin-top: 6px;
	margin-bottom: 16px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 11px;
	text-align: right;
	line-height: 15px;
	padding: 0px 1px 4px;

	a {
		color: #999999;
	}
`;

export const ExternalProvidersLoading = styled(LoadingIndicator)`
	margin-top: 4px;
	margin-bottom: 4px;
`;

export const BackToAcrossLink = styled.a`
	margin-top: 16px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	display: block;
	font-size: 13px;
`;