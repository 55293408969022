import http from "../../infrastructure/httpClient";

const ResendConfirmationEmailService = {
	resendConfirmationEmail: (email: string, returnUrl: string | null): Promise<void> =>
		http.post("resend-confirmation-email", {
			email,
			returnUrl
		})
};

export default ResendConfirmationEmailService;