import React from "react";
import { InputGroup, InputGroupProps } from "reactstrap";

interface IProps {
	className?: string;
	isInvalid?: boolean;
}

export const InputGroupWithValidation = (p: IProps & InputGroupProps) => {
	const { className, isInvalid, ...props } = p;
	const resultClassName = p.isInvalid
			? (className ? `${className} is-invalid` : "is-invalid")
			: className;

	return <InputGroup {...props} className={resultClassName} />;
};