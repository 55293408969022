import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { css, keyframes } from "styled-components";

export enum EmailCheckingState {
	Checking,
	Invalid,
	Valid
}

interface IProps {
	className?: string;
	state?: EmailCheckingState;
}

const EmailCheckingIndicatorComponent = (p: IProps) =>
	<React.Fragment>
		{p.state === EmailCheckingState.Checking &&
			<FontAwesomeIcon icon="spinner" className={p.className} />}
		{p.state === EmailCheckingState.Valid &&
			<FontAwesomeIcon icon="check" className={p.className} />}
	</React.Fragment>;

const Rotate = keyframes`
	100% {
		transform: rotate(360deg);
	}
`;

const RotateAnimation = css`
	animation: ${Rotate} 3.5s linear 0s infinite normal;
`;

export const EmailCheckingIndicator = styled(EmailCheckingIndicatorComponent)`
	&& {
		color: #3c763d;
		position: absolute;
		right: 0;
		width: 34px;
		height: 34px;
		padding: 9px 7px;
		z-index: 100;

		${p => p.state === EmailCheckingState.Checking && RotateAnimation}
	}
`;