import http from "../../infrastructure/httpClient";

export interface IFrameLogoutInfoDto {
	clientId: string;
	signOutIFrameUrl: string;
	postLogoutRedirectUri: string;
}

const LoginService = {
	getEndSessionInfo: (logoutId: string): Promise<IFrameLogoutInfoDto> =>
		http.get("account/logout/end-session", { logoutId })
};

export default LoginService;