import { computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Progress } from "reactstrap";
import styled from "styled-components";

const ProgressBar = styled(Progress)`
	width: 100%;
	height: 3px;
	border-radius: 0;
	background-color: #e5e5e5;

	.progress-bar {
		background-color: ${p => p["data-cm-valid"] ? p.theme.colors.primary : "#e74c3c"}
	}
`;

interface IProps {
	password?: string;
	minLength: number;
	strongLength: number;
}

@observer
export class PasswordStrength extends React.Component<IProps> {
	@computed get passwordStrength(): number {
		const p = this.props.password;
		if (!p) {
			return 0;
		}

		// The result of password length check is expressed as a number from 0 to 1.
		const lengthStrength = p.length / this.props.strongLength;
		// Successful complexity tests percent expressed in 0 to 1.
		const complexityStrength = p.length >= this.props.minLength
			? this.complexityTests.filter(doesPass => doesPass(p)).length
				/ this.complexityTests.length
			: 0;

		const strength = Math.max(lengthStrength, complexityStrength);
		return Math.min(1, strength);
	}

	@computed get isValid(): boolean {
		return (this.props.password || "").length >= this.props.minLength;
	}

	private readonly complexityTests: Array<((value: string) => boolean)> = [
		// Contains numbers.
		(value: string) => /[0-9]/.test(value),
		// Contains special characters.
		(value: string) => /[^a-zA-Z0-9]/.test(value),
		// Contains lower case characters.
		(value: string) => value !== value.toUpperCase(),
		// Contains upper case characters.
		(value: string) => value !== value.toLowerCase()
	];

	public render(): React.ReactNode {
		return (
			<ProgressBar
				className="password-strength"
				value={this.passwordStrength}
				max={1}
				data-cm-valid={this.isValid} />
		);
	}
}