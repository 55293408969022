import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ErrorInfo } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { log, LogLevel } from "../infrastructure/log";

const Header = styled(ModalHeader)`
	border-bottom: none;
`;

const Footer = styled(ModalFooter)`
	border-top: none;
	padding-top: 0px;
`;

const Body = styled(ModalBody)`
	padding: 0px;
	margin-left: 16px;
	margin-right: 16px;
	font-size: 13px;
`;

interface IEnvironmentInfo {
	path: string;
	userAgent: string;
}

interface IReactError extends Error {
	componentStack?: string;
}

interface IState {
	message: string | null;
	hasRenderError: boolean | null;
}

// React state used instead of MobX to avoid possible MobX bugs with rendering which will prevent to
// show error message.
export class ErrorHandler extends React.Component<{}, IState> {
	constructor(p: {}) {
		super(p);

		// Handle uncaught errors.
		window.onerror = this.onError;
		// Handle async unhandled promise rejections.
		window.addEventListener("unhandledrejection", this.onPromiseRejection);

		this.state = { message: null, hasRenderError: null };
	}

	private readonly closeErrorDialog = () => {
		this.setState({ message: null, hasRenderError: null });
	}

	private showErrorMessage(errorMessage: string): void {
		if (this.state.message) {
			// Ignore error if another error message is showing.
			return;
		}

		this.setState({ message: errorMessage });
	}

	private getEnvironmentInfo(): IEnvironmentInfo {
		return {
			path: window.location.pathname + window.location.search,
			userAgent: navigator.userAgent
		};
	}

	private readonly onPromiseRejection = (event: PromiseRejectionEvent) => {
		log("PromiseRejection", LogLevel.Fatal, this.getEnvironmentInfo(), event.reason);
		this.showErrorMessage(event.reason.message);

		// Prevents firing of the default event handler, to don't log error into console,
		// because the logging is done here. There is a difference in how it works in browsers:
		// Chrome expects 'false', but other browsers expect 'true'.
		// We return false, because Chrome is more popular.
		return false;
	}

	private readonly onError = (
		event: string | Event,
		source?: string,
		lineno?: number,
		colno?: number,
		error?: Error) => {
		if (!error) {
			error = new Error(event.toString());
		}
		// If some of these properties are not 'undefined', then they were set by the browser
		// and may not be changed, otherwise an exception could be thrown.
		if (error.name === undefined) {
			error.name = "Unknown exception.";
		}
		if (error.message === undefined) {
			error.message = error.name;
		}
		log("Error", LogLevel.Fatal, this.getEnvironmentInfo(), error);
		this.showErrorMessage(error.message);

		// Prevents firing of the default event handler, to don't log error into console,
		// because the logging is done here. There is a difference in how it works in browsers:
		// Chrome expects 'false', but other browsers expect 'true'.
		// We return false, because Chrome is more popular.
		return false;
	}

	/** Handle react rendering errors. */
	public componentDidCatch(error: IReactError, errorInfo: ErrorInfo): void {
		error.componentStack = errorInfo.componentStack;
		log("React", LogLevel.Fatal, this.getEnvironmentInfo(), error);
		this.showErrorMessage(error.message);
	}

	public static getDerivedStateFromError(error: Error): IState {
		// Update state so the next render will show the fallback UI.
		return { message: error.message, hasRenderError: true };
	}

	public render(): React.ReactNode {
		return (
			<React.Fragment>
				{ this.state.message && <Modal isOpen size="lg">
					<Header>
						<FontAwesomeIcon icon="exclamation-circle" /> Error
					</Header>
					<Body>
						{this.state.message}
					</Body>
					<Footer>
						<Button color="default" onClick={this.closeErrorDialog}>OK</Button>
					</Footer>
				</Modal> }
				{!this.state.hasRenderError && this.props.children}
			</React.Fragment>
		);
	}
}