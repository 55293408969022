import React from "react";
import styled, { keyframes } from "styled-components";

const DelayedShowAnimation = keyframes`
	to {
		visibility: visible;
	}
`;

const Container = styled.div`
	margin: 60px auto;
	width: 190px;
	text-align: center;
	visibility: hidden;
	animation: 0s linear 1s forwards ${DelayedShowAnimation};
`;

const BounceAnimation = keyframes`
	0%, 80%, 100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
`;

const Bounce = styled.div<{ animationDelay?: number }>`
	display: inline-block;
	width: 18px;
	height: 18px;
	margin: 0.5em;
	background-color: #94bcd8;
	border-radius: 100%;
	animation: ${BounceAnimation} 1.4s infinite ease-in-out both;
	animation-delay: ${p => `${p.animationDelay || 0}s`};
`;

export const LoadingIndicator = (p: {className?: string}) =>
	<Container className={p.className}>
		<Bounce animationDelay={-0.32} />
		<Bounce animationDelay={-0.16} />
		<Bounce />
	</Container>;