import { RouteComponentProps } from "react-router-dom";
import { getQueryStringParameter } from "./queryString";

/**
 * Adds current 'ReturnUrl' query parameter if present to the specified relative URL.
 * @param relativeUrl The original relative URL.
 * @param queryParams The URL query parameters dictionary.
 */
export function withReturnUrl(
	relativeUrl: string,
	queryParams: { [key: string]: string } = {}): string {
	// Fake URL base required to create the URL object.
	const u = new URL(relativeUrl, window.location.origin);

	Object.keys(queryParams)
		.forEach(key => u.searchParams.set(key, queryParams[key]));

	const returnUrlParamName = "ReturnUrl";
	const returnUrl = getQueryStringParameter(returnUrlParamName);
	if (returnUrl) {
		u.searchParams.set(returnUrlParamName, returnUrl);
	}

	return `${u.pathname}${u.search}`;
}

/**
 * Navigates to specified relative URL.
 * @param relativeUrl The relative URL.
 * @param props The route component props.
 * @param queryParams The URL query parameters dictionary.
 */
export function goTo(
	relativeUrl: string,
	props: RouteComponentProps,
	queryParams?: { [key: string]: string }): void {
	props.history.push(withReturnUrl(relativeUrl, queryParams));
}