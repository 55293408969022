import { log, LogLevel } from "../infrastructure/log";

// The getQueryStringParameter() is executed many times when a page is rendered. We don't
// want too many messages sent to the server log.
let wasExecuted = false;

const warn = (msg: string) => wasExecuted || log("QueryString", LogLevel.Warn, msg);

export function getQueryStringParameter(
	paramName: string,
	query: string = window.location.search): string | null {
	try {
		const searchParams = new URLSearchParams(query);

		// Normal path for normal browsers.
		if (searchParams && searchParams.get) {
			return searchParams.get(paramName);
		}

		if (!searchParams) {
			warn(`'searchParams' is '${searchParams}'.`);
		} else {
			warn(`'searchParams.get' is '${searchParams.get}'.`);
		}

		// Fallback path for strange browsers / addons / antiviruses.
		if (URLSearchParams.prototype && URLSearchParams.prototype.get) {
			warn(`Using 'URLSearchParams.prototype.get' instead of not available 'searchParams.get'.`);
			return URLSearchParams.prototype.get.call(searchParams, paramName);
		}

		if (!URLSearchParams.prototype) {
			warn(`'URLSearchParams.prototype' is '${URLSearchParams.prototype}'.`);
		} else if (!URLSearchParams.prototype.get) {
			warn(`'URLSearchParams.prototype.get' is '${URLSearchParams.prototype.get}'.`);
		}

		// No fallback available :(
		return null;
	} finally {
		wasExecuted = true;
	}
}