import http from "../../infrastructure/httpClient";

interface IServerErrorDto {
	error: string;
	description: string;
}

const ServerErrorService = {
	getError: (errorId: string): Promise<IServerErrorDto> =>
		http.get("server-error", { errorId })
};

export default ServerErrorService;