import styled from "styled-components";

export const PullRightContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;

	* {
		margin-left: 3px;
	}
`;